// import { useContext } from "react";
import "./AboutUs.css";
// import WebplaceContext from "../../../store/webplace-context";

import aboutUsBanner from "../../../assets/mainItems/aboutUsBanner.png"

const AboutUs = () => {
  // const placeCtx = useContext(WebplaceContext).homeAboutus;
  // const stylesCtx = useContext(WebplaceContext).homeAboutus.aboutus_styles;

  return (
    <div className="about-wrapper" aria-label="About Us Image">
      <h1 className="restaurant-title">Mimo Pizza</h1>
      {/* <div className="about-title" style={{ color: stylesCtx.title_color }}>
        {placeCtx.title}
      </div>
      <div className="about-text">
        {placeCtx.aboutus_description.map((about, index) => {
          return (
            <div key={index} className="text-style" style={{ color: stylesCtx.font_color }}>
              {about}
            </div>
          );
        })}
      </div> */}
      <img src={aboutUsBanner} alt="About Us Miami Owned Pizza" className="about-us-image" />
    </div>
  );
};

export default AboutUs;
